<form [formGroup]="dateSelect">
  <h2 mat-dialog-title>{{ data.title }}</h2>
  <div mat-dialog-content>
    <p>{{ data.description }}</p>
  </div>

  <div mat-dialog-actions>
    <mat-card class="full">
      <mat-grid-list cols="2" rowHeight="100px">
        <mat-grid-tile [colspan]="1">
          <mat-form-field>
            <mat-label>Data Produção</mat-label>
            <input
              matInput
              formControlName="date_select"
              [matDatepicker]="dateSelected"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="dateSelected"
            ></mat-datepicker-toggle>
            <mat-datepicker #dateSelected></mat-datepicker>
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile colspan="2">
          <mat-form-field>
            <mat-label>Local</mat-label>
            <mat-select formControlName="localId">
              <mat-option *ngFor="let local of locals" [value]="local.localId">
                {{ local.description }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>
    </mat-card>
    <div mat-dialog-actions>
      <button mat-button color="warn" (click)="close()" cdkFocusInitial>
        Cancelar
      </button>
      <button mat-button (click)="save()">Confirmar</button>
    </div>
  </div>
</form>
