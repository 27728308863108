import { Component, OnInit, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { LocalInterface } from "../../../../../src/app/interfaces/stock/LocalInterface";
import { LocalService } from "src/app/services/stock/local/local.service";
import { ContextService } from "src/app/services/context/context.service";

export interface DateModalComponent {
  title: string;
  description: string;
}

@Component({
  selector: "pec-date-production-modal",
  templateUrl: "./date-production-modal.component.html",
  styleUrls: ["./date-production-modal.component.scss"],
})
export class DateModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: DateModalComponent,
    private localService: LocalService,
    private context_service: ContextService
  ) {}

  dateSelect = new FormGroup({
    date_select: new FormControl(""),
    localId: new FormControl("", [Validators.required]),
  });

  locals: LocalInterface[];

  ngOnInit(): void {
    this.loadSelectLocal();
  }

  save(): void {
    this.dialogRef.close(this.dateSelect.value);
  }

  close(): void {
    this.dialogRef.close(false);
  }

  loadSelectLocal() {
    this.localService.getAll(1, 100).subscribe((value) => {
      this.locals = value.items;
    });
  }
}
