import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import {
  BottomSheetComponent,
  HtmlData,
} from "src/app/components/shared/bottom-sheet/bottom-sheet.component";
import {
  CONFIG_SMART_REPORT,
  SMART_TYPE_REPORT,
  SmartReportModalComponent,
  TDataSmartReport,
} from "src/app/components/shared/smart-report-modal/smart-report-modal.component";
import { GenericFilterTypeEnum } from "src/app/enums/GenericFilterTypeEnum";
import { BreedInterface } from "src/app/interfaces/animal/BreedInterface";
import { FarmAreaInterface } from "src/app/interfaces/farm/FarmArea";
import { ReportInterface } from "src/app/interfaces/report/report-interface";
import { PecGenericFilterFields } from "src/app/interfaces/utils/PecGenericFilterFields";
import { PecGenericFilterList } from "src/app/interfaces/utils/PecGenericFilterList";
import PecControlHeaderRequest from "src/app/utils/PecControlHeaderRequest";
import { BreedService } from "../../animal/breed/breed.service";
import { AuthService } from "../../auth/auth.service";
import { ContextService } from "../../context/context.service";
import { FarmAreaService } from "../../farm/farmArea/farm-area.service";

export type TAnimalReport = {
  farmId: number;
  status: string;
  breedId: number;
  areas: [];
  minWeight: number;
  maxWeight: number;
  minAge: number;
  maxAge: number;
};

@Injectable({
  providedIn: "root",
})
export class CriaAnimalInfoReportService
  implements ReportInterface<TAnimalReport, Blob>
{
  farmId!: number;
  reportFilters: TAnimalReport;
  title = `CRIA - Informações Animais`;
  filterBreed: PecGenericFilterList[] = [];
  filterArea: PecGenericFilterList[] = [];
  reportFilterFields: PecGenericFilterFields[] = [
    {
      fieldName: "status",
      type: GenericFilterTypeEnum.SELECT,
      label: "Status",
      fieldValue: "",
      valuesList: [
        {
          fieldLabel: "Ativo",
          fieldValue: "1",
        },
        {
          fieldLabel: "Inativo",
          fieldValue: "0",
        },
      ],
      required: true,
      hint: "Campo obrigatório",
    },
    {
      fieldName: "sex",
      type: GenericFilterTypeEnum.SELECT,
      label: "Selecione o Sexo",
      fieldValue: "",
      valuesList: [
        { fieldLabel: "Macho", fieldValue: "M" },
        { fieldLabel: "Fêmea", fieldValue: "F" },
      ],
    },
    {
      fieldName: "breedId",
      type: GenericFilterTypeEnum.SELECT,
      label: "Selecione a raça",
      fieldValue: "",
      valuesList: this.filterBreed,
    },
    {
      fieldName: "areas",
      type: GenericFilterTypeEnum.MULTISELECT,
      label: "Selecione a Localização",
      fieldValue: "",
      valuesList: this.filterArea,
    },
    {
      fieldName: "minWeight",
      type: GenericFilterTypeEnum.NUMBER,
      label: "Peso mínimo",
      fieldValue: "",
    },
    {
      fieldName: "maxWeight",
      type: GenericFilterTypeEnum.NUMBER,
      label: "Peso máximo",
      fieldValue: "",
    },
    {
      fieldName: "minAge",
      type: GenericFilterTypeEnum.NUMBER,
      label: "Idade mínima",
      fieldValue: "",
    },
    {
      fieldName: "maxAge",
      type: GenericFilterTypeEnum.NUMBER,
      label: "Idade máxima",
      fieldValue: "",
    },
  ];
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private contextService: ContextService,
    private dialog: MatDialog,
    private _bottomSheet: MatBottomSheet,
    private breedService: BreedService,
    private readonly areaService: FarmAreaService
  ) {
    this.getBreed();
    this.getArea();
  }

  private getArea() {
    let filterArea = [];
    filterArea.push(
      {
        fieldName: "farmId",
        fieldValue: String(this.contextService.getFarm()),
      },
      { fieldName: "status", fieldValue: "1" }
    );
    this.areaService.getAll(1, 1000, filterArea).subscribe((areas) => {
      areas.items.forEach((element: FarmAreaInterface) => {
        this.filterArea.push({
          fieldLabel: element.description,
          fieldValue: String(element.farmAreaId),
        });
      });
      this.filterArea = this.filterArea.sort((a: any, b: any) =>
        a.fieldLabel < b.fieldLabel ? -1 : 1
      );
    });
  }

  getBreed() {
    this.breedService.getAll(1, 1000).subscribe((breeds) => {
      if (breeds.items) {
        breeds.items.forEach((element: BreedInterface) => {
          this.filterBreed.push({
            fieldLabel: element.description,
            fieldValue: String(element.breedId),
          });
        });
      }
    });
  }

  openSmartReport() {
    const descriptionFarm = this.contextService.getCurrentDescriptionFarm();
    this.title = `Cria - Informações Animais-${descriptionFarm}`;

    this.farmId = this.contextService.getFarm();
    this.reportFilters = {
      farmId: this.farmId,
      status: null,
      breedId: null,
      areas: [],
      minWeight: null,
      maxWeight: null,
      minAge: null,
      maxAge: null,
    };

    const data: TDataSmartReport<TAnimalReport> = {
      title: this.title,
      description: "Selecione as opções para gerar o relatório",
      requiredFilters: true,
      reportFilters: this.reportFilters,
      reportFilterFields: this.reportFilterFields,
      service: this,
      reportTypes: [SMART_TYPE_REPORT.excel],
    };
    this.dialog.open(SmartReportModalComponent, {
      width: CONFIG_SMART_REPORT.width,
      height: CONFIG_SMART_REPORT.height,
      data,
    });
  }

  openInfoReport() {
    const data: HtmlData = {
      title: this.title,
      subtitle:
        "Relatório com as informações dos animais e quantidade total da Fazenda.",
      description: [
        "Informações Contidas no relatório",
        "-> Quantidade de animais totais",
        "-> SISBOV",
        "-> Raça",
        "-> Sexo",
        "-> Idade em mêses",
        "-> Peso",
        "-> Status",
        "-> Identificação Visual",
        "-> Identificação Eletrônico",
        "-> Características",
        "-> Histórico Sanitário",
        "-> Área",
        "-> Fornecedor",
      ],
    };
    this._bottomSheet.open(BottomSheetComponent, { data });
  }

  getReport(datas: TAnimalReport): any {
    if (datas.areas.length > 0) {
      let arrAreas: any = [];
      datas.areas.forEach((row) => {
        arrAreas.push({ areaId: parseInt(row) });
      });
      datas.areas = arrAreas;
    }
    let config: any = {
      responseType: "blob",
      timeout: 500000,
      headers: PecControlHeaderRequest.getHeader(this.authService.token),
    };
    return this.http.post(
      `${this.authService.getUrlBackEnd()}/api/reports/cria/animalInfo`,
      datas,
      config
    );
  }
}
