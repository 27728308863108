import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../../auth/auth.service';
import {LocalInterface} from '../../../interfaces/stock/LocalInterface';
import {Observable} from 'rxjs';
import {SERVER_URL} from '../../../config/env';
import PecControlHeaderRequest from '../../../utils/PecControlHeaderRequest';
import {GenericFilterResponse} from '../../../components/shared/pec-common-filter/interaces/GenericFilterResponse';

import {ContextService} from '../../context/context.service';

@Injectable({
  providedIn: 'root'
})
export class LocalService {

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private contextService: ContextService
  ) { }

  farmId = this.contextService.getFarm()

  create(local: LocalInterface): Observable<any> {
    return this.http.post(`${this.authService.getUrlBackEnd()}/api/local`, {local, farmId: this.farmId}, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }

  getAll(page: number, limit: number, filter?: GenericFilterResponse[]): Observable<any> {
    let filterQueryString = '';
    if (filter) {


      for (const f of filter) {
        if (f.fieldName && f.fieldValue) {
          filterQueryString += `&${f.fieldName}=${f.fieldValue}`;
        }
      }
    }
    filterQueryString += `&${'farmId'}=${this.contextService.getFarm()}`;



    return this.http.get(`${this.authService.getUrlBackEnd()}/api/local?page=${page}&limit=${limit}${filterQueryString}&farmId=${this.farmId}`, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }

  getById(localId: number): Observable<any> {
    return this.http.get(`${this.authService.getUrlBackEnd()}/api/local/${localId}`, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }

  update(local: LocalInterface): Observable<any> {
    return this.http.put(`${this.authService.getUrlBackEnd()}/api/local`, {local, farmId: this.farmId}, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }

  delete(localId: number): Observable<any> {
    return this.http.delete(`${this.authService.getUrlBackEnd()}/api/local${localId}`, {
      headers: PecControlHeaderRequest.getHeader(this.authService.token)
    });
  }


}
